var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inDetails"},[_c('div',{staticClass:"top"},[_c('BreadCrumbb',{staticStyle:{"width":"1200px","margin":"0 auto"}})],1),_c('div',{staticClass:"main-card",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"cell-left"},[(_vm.httpShow)?_c('div',[_c('div',{staticClass:"title"},[_vm._v("欢迎入驻Shopee")]),_vm._l((_vm.info),function(item,key,index){return _c('div',{key:index},[_c('div',{staticClass:"info-list"},[_c('div',{staticClass:"info-title"},[_vm._v(_vm._s(item.title))]),_vm._l((item.data),function(item,key,index){return _c('div',{key:index,staticClass:"info-list_item"},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(item.name))]),(
                  typeof item.value == 'string' ||
                  typeof item.value == 'number'
                )?_c('div',{staticClass:"right"},[_vm._v(" "+_vm._s(item.value)+" ")]):_vm._e(),(typeof item.value == 'object')?_c('div',{staticClass:"right-mg",staticStyle:{"position":"relative"}},[(
                    item.name != '登录店铺后台视频' &&
                    item.name != '其他视频验证'
                  )?_c('div',_vm._l((item.value),function(val,index){return _c('el-image',{key:index,staticStyle:{"width":"178px","height":"108px","border-radius":"10px","margin-right":"10px","background":"#e6e6e6"},attrs:{"src":val,"fit":"contain"}},[_c('svg',{staticStyle:{"width":"50px","height":"50px"},attrs:{"slot":"error","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 54 61","fill":"#e5e4e4"},slot:"error"},[_c('path',{attrs:{"d":"M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z"}})])])}),1):_vm._e(),(
                    (item.name == '登录店铺后台视频' ||
                      item.name == '其他视频验证') &&
                    item.value != ''
                  )?_c('div',_vm._l((item.value),function(val,index){return _c('video',{key:index,ref:"video",refInFor:true,staticStyle:{"width":"400px"},attrs:{"controls":"","id":"video","src":val}})}),0):_vm._e(),(
                    (item.name == '登录店铺后台视频' ||
                      item.name == '其他视频验证') &&
                    item.value == ''
                  )?_c('div',[_vm._v(" 未上传 ")]):_vm._e()]):_vm._e()])})],2)])})],2):_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('img',{attrs:{"src":_vm.httpImg,"alt":""}})])]),_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cell-right"},[_c('div',{staticClass:"r-box"},[_c('div',{staticClass:"r-line"})]),_c('div',{staticClass:"neet"},[_c('div',{staticStyle:{"color":"#f17866"}},[_vm._v("法人信息")]),_c('div',[_vm._v("基本信息")]),_c('div',[_vm._v("公司信息")]),_c('div',[_vm._v("店铺信息")])])])}]

export { render, staticRenderFns }