<template>
  <div class="inDetails">
    <div class="top">
      <BreadCrumbb style="width: 1200px; margin: 0 auto"></BreadCrumbb>
    </div>
    <div class="main-card" style="display: flex">
      <div class="cell-left">
        <div v-if="httpShow">
          <div class="title">欢迎入驻Shopee</div>
          <div v-for="(item, key, index) in info" :key="index">
            <div class="info-list">
              <div class="info-title">{{ item.title }}</div>
              <div
                class="info-list_item"
                v-for="(item, key, index) in item.data"
                :key="index"
              >
                <div class="left">{{ item.name }}</div>
                <div
                  class="right"
                  v-if="
                    typeof item.value == 'string' ||
                    typeof item.value == 'number'
                  "
                >
                  {{ item.value }}
                </div>
                <div
                  class="right-mg"
                  style="position: relative"
                  v-if="typeof item.value == 'object'"
                >
                  <div
                    v-if="
                      item.name != '登录店铺后台视频' &&
                      item.name != '其他视频验证'
                    "
                  >
                    <el-image
                      v-for="(val, index) in item.value"
                      :key="index"
                      style="
                        width: 178px;
                        height: 108px;
                        border-radius: 10px;
                        margin-right: 10px;
                        background: #e6e6e6;
                      "
                      :src="val"
                      fit="contain"
                    >
                      <svg
                        slot="error"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 54 61"
                        fill="#e5e4e4"
                        style="width: 50px; height: 50px"
                      >
                        <path
                          d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z"
                        /></svg
                    ></el-image>
                  </div>
                  <!-- {{ item }} -->
                  <!-- <svg
                  v-if="
                    item.name == '登录店铺后台视频' ||
                    item.name == '其他视频验证'
                  "
                  @click="handleOpen"
                  enable-background="new 0 0 15 15"
                  viewBox="0 0 15 15"
                  x="0"
                  y="0"
                  class="shopee-svg-icon sFD+z6"
                  style="
                    width: 32px;
                    height: 32px;
                    position: absolute;
                    inset: 0px;
                    margin: auto;
                    z-index: 1;
                    cursor: pointer;
                  "
                >
                  <g opacity=".54">
                    <g>
                      <circle cx="7.5" cy="7.5" fill="#040000" r="7.3"></circle>
                      <path
                        d="m7.5.5c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0-.5c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5-3.4-7.5-7.5-7.5z"
                        fill="#ffffff"
                      ></path>
                    </g>
                  </g>
                  <path
                    d="m6.1 5.1c0-.2.1-.3.3-.2l3.3 2.3c.2.1.2.3 0 .4l-3.3 2.4c-.2.1-.3.1-.3-.2z"
                    fill="#ffffff"
                  ></path>
                </svg> -->
                  <div
                    v-if="
                      (item.name == '登录店铺后台视频' ||
                        item.name == '其他视频验证') &&
                      item.value != ''
                    "
                  >
                    <video
                      v-for="(val, index) in item.value"
                      :key="index"
                      controls
                      id="video"
                      ref="video"
                      style="width: 400px"
                      :src="val"
                    ></video>
                  </div>
                  <div
                    v-if="
                      (item.name == '登录店铺后台视频' ||
                        item.name == '其他视频验证') &&
                      item.value == ''
                    "
                  >
                    未上传
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; text-align: center" v-else>
          <img :src="httpImg" alt="" />
        </div>
      </div>
      <div class="cell-right">
        <div class="r-box">
          <div class="r-line"></div>
        </div>
        <div class="neet">
          <div style="color: #f17866">法人信息</div>
          <div>基本信息</div>
          <div>公司信息</div>
          <div>店铺信息</div>
        </div>
      </div>
    </div>

    <!-- <el-dialog
      title="视频预览"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
    >
      <div>
        <video ref="video" controls style="width: 100%" :src="videoUrl"></video>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import BreadCrumbb from "@/components/BreadCrumbb";
import { inList } from "@/api/main.js";
import { configList, getMainCategory } from "@/api/common.js";
export default {
  data() {
    return {
      info: {
        legal: {
          title: "法人信息",
          data: {
            name: { name: "真实姓名", value: " " },
            number: { name: "身份证号", value: " " },
            img: { name: "法人身份证照片", value: [] },
          },
        },
        basic: {
          title: "基本信息",
          data: {
            contact_name: { name: "联系人姓名", value: " " },
            contact_position: { name: "联系人职位", value: " " },
            company_email: { name: "公司邮箱", value: " " },
            contact_mobile: { name: "联系人手机号", value: " " },
            manage_type: { name: "过往主要经营经验", value: " " },
          },
        },
        company: {
          title: "公司信息",
          data: {
            company_license: {
              name: "公司营业执照原件(正副本)照片",
              value: [],
            },
            // company_name: { name: "申请时公司名称", value: " " },
            company_name1: { name: "营业执照公司名称", value: " " },
            company_credit_code: {
              name: "营业执照统一企业信用代码",
              value: " ",
            },
            // company_country: { name: "主要经营地区", value: " " },
            // company_country1: { name: "主要经营平台", value: " " },
            // company_country2: { name: "意愿开通首战地区", value: " " },
            // url: { name: "主要店铺链接", value: " " },
            company_income: { name: "全年流水金额", value: " " },
            company_total: { name: "公司总人数", value: " " },
            company_is_factory: { name: "是否有工厂", value: " " },
            company_address: { name: "办公地址", value: " " },
          },
        },
        shop: {
          title: "店铺信息",
          data: {
            shop_main_cate: { name: "主要品类", value: " " },
            shop_sub_cate: { name: "次要品类", value: " " },
            shop_goods_price: { name: "平均商品单价", value: " " },
            shop_goods_num: { name: "计划在Shopee上架产品的数量", value: " " },
            code: { name: "预计在Shopee投入的前期资金", value: " " },
            shop_supply_source: { name: "主要货源渠道", value: " " },
            shop_ship_mode: { name: "主要发货模式", value: " " },
            shop_address: { name: "退货地址", value: " " },
            is_brand: { name: "是否为品牌", value: " " },
            shop_video: { name: "登录店铺后台视频", value: [] },
            shop_other_video: { name: "其他视频验证", value: [] },
          },
        },
      },
      options: {},
      dialogVisible: false,
      videoUrl: "",
      httpShow: false,
      httpImg: require("../../../assets/img/app/http.gif"),
    };
  },
  created() {
    let index = localStorage.ININDEX;
    // 获取入驻基本配置
    configList().then((res) => {
      if (res.code == 1) {
        this.options = res.data;
        // 获取主要品类
        getMainCategory().then((res) => {
          if (res.code == 1) {
            this.options.category = res.data;
            inList().then((res) => {
              if (res.code == 1) {
                this.httpShow = true;

                // 法人信息
                this.info.legal.data.name.value = res.data.list[index].name;
                this.info.legal.data.number.value = res.data.list[index].number;
                this.info.legal.data.img.value.push(
                  res.data.list[index].id_card_url_front
                );
                this.info.legal.data.img.value.push(
                  res.data.list[index].id_card_url_back
                );
                // 基本信息
                this.info.basic.data.contact_name.value =
                  res.data.list[index].contact_name;

                this.info.basic.data.contact_position.value =
                  this.options.nature[res.data.list[index].contact_position];
                this.info.basic.data.company_email.value =
                  res.data.list[index].company_email;
                this.info.basic.data.contact_mobile.value =
                  res.data.list[index].contact_mobile;
                this.info.basic.data.manage_type.value =
                  this.options.business_experience[
                    res.data.list[index].manage_type
                  ];
                // 公司信息
                this.info.company.data.company_license.value.push(
                  res.data.list[index].company_info.company_license
                );
                // this.info.company.data.company_name.value =
                //   res.data.list[index].company_info.company_name;
                this.info.company.data.company_name1.value =
                  res.data.list[index].company_info.company_name;
                this.info.company.data.company_credit_code.value =
                  res.data.list[index].company_info.company_credit_code;
                // this.info.company.data.company_country.value =
                //   res.data.list[index].company_info.company_country;
                // this.info.company.data.company_country1.value =
                //   res.data.list[index].company_info.company_country;
                // this.info.company.data.company_country2.value =
                //   res.data.list[index].company_info.company_country;
                this.info.company.data.company_income.value =
                  this.options.company_flow[
                    res.data.list[index].company_info.company_income
                  ];
                this.info.company.data.company_total.value =
                  this.options.company_total[
                    res.data.list[index].company_info.company_total
                  ];
                this.info.company.data.company_is_factory.value =
                  res.data.list[index].company_info.company_is_factory == 1
                    ? "是"
                    : "否";
                this.info.company.data.company_address.value =
                  res.data.list[index].company_info.company_province +
                  res.data.list[index].company_info.company_city +
                  res.data.list[index].company_info.company_area +
                  res.data.list[index].company_info.company_address;
                // 店铺信息
                if (this.options.category) {
                  this.info.shop.data.shop_main_cate.value =
                    this.options.category[
                      res.data.list[index].shop_info.shop_main_cate - 1
                    ].name;
                  this.info.shop.data.shop_sub_cate.value =
                    this.options.category[
                      res.data.list[index].shop_info.shop_sub_cate - 1
                    ].name;
                }
                this.info.shop.data.shop_goods_price.value =
                  this.options.shop_goods_price[
                    res.data.list[index].shop_info.shop_goods_price
                  ];
                this.info.shop.data.shop_goods_num.value =
                  this.options.shop_goods_num[
                    res.data.list[index].shop_info.shop_goods_num
                  ];
                this.info.shop.data.code.value =
                  this.options.shop_invest_funds[
                    res.data.list[index].shop_info.shop_throw_money
                  ];
                this.info.shop.data.shop_supply_source.value =
                  this.options.shop_supply_channel[
                    res.data.list[index].shop_info.shop_supply_source
                  ];
                this.info.shop.data.shop_ship_mode.value =
                  res.data.list[index].shop_info.shop_ship_mode == 0
                    ? "提前备货"
                    : "收到订单后进行采购或者生产备货";
                this.info.shop.data.shop_address.value =
                  res.data.list[index].shop_info.shop_province +
                  res.data.list[index].shop_info.shop_city +
                  res.data.list[index].shop_info.shop_area +
                  res.data.list[index].shop_info.shop_address;
                this.info.shop.data.is_brand.value =
                  res.data.list[index].shop_info.is_brand == 0 ? "否" : "是";
                this.info.shop.data.shop_video.value.push(
                  res.data.list[index].shop_info.shop_video
                );
                this.info.shop.data.shop_other_video.value.push(
                  res.data.list[index].shop_info.shop_other_video
                );
              }
            });
          }
        });
      }
    });
  },
  mounted() {},
  methods: {
    handleOpen() {
      // this.videoUrl = val.value[0];
      // this.dialogVisible = true;
      // this.$nextTick(() => {
      //   this.$refs.video.play();
      // });
      let box = document.getElementById("video");
      box.play();
      if (box.requestFullScreen) {
        box.requestFullScreen();
      } else if (box.mozRequestFullScreen) {
        box.mozRequestFullScreen();
      } else if (box.webkitRequestFullScreen) {
        box.webkitRequestFullScreen();
      }
    },
    handleClose() {
      this.$refs.video.pause();
      this.dialogVisible = false;
    },
  },
  components: {
    BreadCrumbb,
  },
};
</script>
<style lang="less" scoped>
.inDetails {
  background: #f2f5fa;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
  }
  .cell-left {
    width: 795px;
    background: #fff;
    padding: 38px 22px;
    box-shadow: 0 0 4px #eee;

    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .info-list {
      padding: 36px 0;
      border-top: 1px solid #e5e5e5;
      //   border-bottom: 1px solid #e5e5e5;
      .info-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
        margin-left: 80px;
      }
      .info-list_item {
        display: flex;
        font-size: 14px;
        padding: 20px 0;
        .left {
          width: 160px;
          text-align: right;
          margin-right: 18px;
        }
      }
    }
  }
  .cell-right {
    width: 354px;
    background: #fff;
    padding: 24px;
    box-shadow: 0 0 4px #eee;
    margin-left: 10px;
    height: 186px;
    display: flex;
    margin-bottom: 600px;
    .r-box {
      width: 2px;
      height: 98px;
      background: #f6f6f6;
      margin-right: 20px;
      .r-line {
        width: 2px;
        height: 18px;
        background: #f17866;
      }
    }
    .neet {
      font-size: 14px;
      div {
        margin-bottom: 20px;
      }
    }
  }
  .video {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 111;
    top: 0;
    video {
      width: 1200px;
      height: 1200px;
    }
  }
}
</style>